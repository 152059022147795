import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    SavedQueriesList,
    FilterLiveSearch,
    Button,
    useNotify,
    CreateButton,
} from 'react-admin';
import { Card, CardContent, Stack, Modal, Box, Typography } from '@mui/material';
import { useState } from 'react';
import CONFIG from '../config';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

export const ExportButton = () => {
    const doExport = () => {
        window.open(`${CONFIG.BACKEND_URL}/identity/v1/validated-user-data/export`, '_blank');
    };

    return <Button label='Export' color='primary' onClick={doExport} startIcon={<DownloadIcon />} />;
};

export const ImportButton = () => {
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false); // Added loading state

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setFile(selectedFile || null);
    };

    const handleUpload = () => {
        if (file) {
            setLoading(true); // Set loading to true
            const formData = new FormData();
            formData.append('file', file);

            fetch(`${CONFIG.BACKEND_URL}/identity/v1/validated-user-data/import`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            })
                .then((response) => {
                    if (response.ok) {
                        notify('Imported successfully', { type: 'success' });
                    } else {
                        notify('Failed to import', { type: 'error' });
                    }

                    setOpen(false);
                    setFile(null);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Button
                label='Import'
                color='secondary'
                onClick={() => setOpen(true)}
                startIcon={<UploadIcon />}
                disabled={loading}
            />
            <Modal
                open={open}
                onClose={() => {
                    if (!loading) setOpen(false);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant='h6' component='h2' mb={2}>
                        Upload CSV File
                    </Typography>
                    <input type='file' accept='.csv' onChange={handleFileChange} disabled={loading} />
                    {file && (
                        <Typography variant='body2' mt={1}>
                            Selected file: {file.name}
                        </Typography>
                    )}
                    <Box mt={3} display='flex' justifyContent='space-between'>
                        <Button onClick={() => setOpen(false)} color='secondary' label='Cancel' disabled={loading} />
                        <Button
                            onClick={handleUpload}
                            color='primary'
                            disabled={!file || loading} // Disable button while loading or no file
                            variant='contained'
                            label={loading ? 'Uploading...' : 'Confirm'} // Show loading text
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export const ValidatedUserFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 'max-content' }}>
        <CardContent>
            <SavedQueriesList />
            <FilterLiveSearch label='Email' source='email__ilike' variant='outlined' />
            <FilterLiveSearch label='Type' source='type__ilike' variant='outlined' />
            <FilterLiveSearch label='Subtype' source='subtype__ilike' variant='outlined' />
        </CardContent>
    </Card>
);

const ValidatedUserList = (props: any) => {
    return (
        <List
            {...props}
            aside={<ValidatedUserFilterSidebar />}
            actions={
                <Stack direction='row' spacing={2} m={1}>
                    <ExportButton />
                    <ImportButton />
                    <CreateButton />
                </Stack>
            }
        >
            <Datagrid>
                <TextField source='email' />
                <TextField source='type' />
                <TextField source='subtype' />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default ValidatedUserList;
