import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const ValidatedUserEdit = (props: any) => {
    return (
        <Edit title='Edit a ValidatedUser' {...props}>
            <SimpleForm>
                <TextInput source='email' disabled />
                <TextInput source='type' />
                <TextInput source='subtype' />
            </SimpleForm>
        </Edit>
    );
};

export default ValidatedUserEdit;
