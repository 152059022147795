import { AuthProvider } from 'react-admin';
import CONFIG from './config';

export const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);

        const request = new Request(`${CONFIG.BACKEND_URL}/identity/v1/auth/token`, {
            method: 'POST',
            body: formData,
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            credentials: 'include',
        });
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            localStorage.setItem('auth-admin', '1');
        } catch {
            throw new Error('Network error');
        }
    },
    checkError: (error: any) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth-admin');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return Boolean(localStorage.getItem('auth-admin')) ? Promise.resolve() : Promise.reject();
    },
    logout: async () => {
        const request = new Request(`${CONFIG.BACKEND_URL}/identity/v1/auth/logout`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
        } catch {}
        localStorage.removeItem('auth-admin');
        return Promise.resolve();
    },
    getIdentity: async () => {
        const request = new Request(`${CONFIG.BACKEND_URL}/identity/v1/users/me`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        try {
            const userData = await fetch(request)
                .then((response) => response.json())
                .then((data) => data);
            if (userData.status < 200 || userData.status >= 300) {
                throw new Error(userData.statusText);
            } else {
                return Promise.resolve({
                    id: userData.id,
                    fullName: `${userData.first_name} ${userData.last_name}`,
                    avatar: undefined,
                });
            }
        } catch {
            throw new Error('Network error');
        }
    },
    getPermissions: () => Promise.resolve(),
};
