import { stringify } from 'query-string';
import { Identifier, fetchUtils, PaginationPayload, FilterPayload, SortPayload, DataProvider } from 'ra-core';

const getPaginationQuery = (pagination: PaginationPayload) => {
    return {
        page: pagination.page,
        page_size: pagination.perPage,
    };
};

const getFilterQuery = (filter: FilterPayload) => {
    const { q: search, ...otherSearchParams } = filter;
    return {
        ...otherSearchParams,
        search,
    };
};

export const getOrderingQuery = (sort: SortPayload) => {
    const { field, order } = sort;
    return {
        sort: `${order === 'ASC' ? '' : '-'}${field}`,
    };
};

const fastApiDataProvider =
    ({ primaryKey = 'id' }: { primaryKey?: string } = { primaryKey: 'id' }) =>
    (apiUrl: string, httpClient: Function = fetchUtils.fetchJson): DataProvider => {
        const getOneJson = (resource: string, id: Identifier) =>
            httpClient(`${apiUrl}/${resource}/${id}`).then((response: Response) => response.json);

        return {
            getList: async (resource, params) => {
                const query = {
                    ...getFilterQuery(params.filter),
                    ...getPaginationQuery(params.pagination),
                    ...getOrderingQuery(params.sort),
                };

                const url = `${apiUrl}/${resource}?${stringify(query)}`;

                const { json } = await httpClient(url);

                return {
                    data: json.results.map((resource: { [x: string]: unknown }) => ({
                        ...resource,
                        id: resource[primaryKey],
                    })),
                    total: json.count,
                };
            },

            getOne: async (resource, params) => {
                const data = await getOneJson(resource, params.id);
                return {
                    data: { ...data, id: data[primaryKey] },
                };
            },

            getMany: (resource, params) => {
                return Promise.all(params.ids.map((id) => getOneJson(resource, id))).then((data) => ({
                    data: data.map((d) => ({ ...d, id: d[primaryKey] })),
                }));
            },

            getManyReference: async (resource, params) => {
                const query = {
                    ...getFilterQuery(params.filter),
                    ...getPaginationQuery(params.pagination),
                    ...getOrderingQuery(params.sort),
                };

                const url = `${apiUrl}/${params.target}/${params.id}/${resource}?${stringify(query)}`;

                const { json } = await httpClient(url);

                return {
                    data: json.results.map((resource: { [x: string]: unknown }) => ({
                        ...resource,
                        id: resource[primaryKey],
                    })),
                    total: json.count,
                };
            },

            update: async (resource, params) => {
                const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(params.data),
                });
                return { data: { ...json, id: json[primaryKey] } };
            },

            updateMany: (resource, params) =>
                Promise.all(
                    params.ids.map((id) =>
                        httpClient(`${apiUrl}/${resource}/${id}`, {
                            method: 'PATCH',
                            body: JSON.stringify(params.data),
                        })
                    )
                ).then((responses) => ({ data: responses.map(({ json }) => json[primaryKey]) })),

            create: async (resource, params) => {
                // M2M
                if (params.meta) {
                    const { record, reference, referenceField } = params.meta;

                    if (record && reference && referenceField) {
                        const referenceId = params.data[referenceField];
                        delete params.data[referenceField];

                        const { json } = await httpClient(
                            `${apiUrl}/${resource}/${record.id}/${reference}/${referenceId}`,
                            {
                                method: 'PUT',
                                body: JSON.stringify(params.data),
                            }
                        );
                        return { data: { id: 1 } }; // Fake res to works on create
                    }
                }

                const { json } = await httpClient(`${apiUrl}/${resource}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                });
                return {
                    data: { ...json, id: json[primaryKey] },
                };
            },

            delete: (resource, params) => {
                // M2M
                if (params.meta) {
                    const reference = resource;
                    const referenceId = params.id;
                    const { resourceId } = params.meta;
                    const mResource = params.meta.resource;

                    if (mResource && resourceId && params.id) {
                        return httpClient(`${apiUrl}/${mResource}/${resourceId}/${reference}/${referenceId}`, {
                            method: 'DELETE',
                        }).then(() => ({ data: params.previousData }));
                    }
                }

                return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'DELETE',
                }).then(() => ({ data: params.previousData }));
            },

            deleteMany: (resource, params) => {
                return Promise.all(
                    params.ids.map((id) =>
                        httpClient(`${apiUrl}/${resource}/${id}`, {
                            method: 'DELETE',
                        })
                    )
                ).then(() => ({ data: [] }));
            },
        };
    };

export default fastApiDataProvider;
