import React from 'react';
import {
    fetchUtils,
    Admin,
    Resource,
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    GET_MANY,
    GET_MANY_REFERENCE,
} from 'react-admin';
import UsersList from './components/UsersList';
import UserEdit from './components/UserEdit';
import ExternalUsersList from './components/ExternalUserList';
import ExternalUserCreate from './components/ExternalUserCreate';
import ExternalUserEdit from './components/ExternalUserEdit';
import PermissionsList from './components/PermissionsList';
import PermissionCreate from './components/PermissionCreate';
import PermissionEdit from './components/PermissionEdit';
import GroupsList from './components/GroupsList';
import GroupCreate from './components/GroupCreate';
import GroupEdit from './components/GroupEdit';
import NotificationsList from './components/NotificationsList';
import fastApiDataProvider from './providers/fastApi';
import mongoExpressDataProvider from './providers/mongoExpress';
import { authProvider } from './AuthProvider';
import PeopleIcon from '@mui/icons-material/People';
import CONFIG from './config';
import ValidatedUserList from './components/ValidatedUserList';
import ValidatedUserCreate from './components/ValidatedUserCreate';
import ValidatedUserEdit from './components/ValidatedUserEdit';

const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
};

const dataProviders = [
    {
        dataProvider: fastApiDataProvider()(`${CONFIG.BACKEND_URL}/identity/v1`, httpClient),
        provider: 'identity',
        resources: [
            'users',
            'external-users',
            'permissions',
            'groups',
            'internal-app-instances',
            'tenant/app-instances',
        ],
    },
    {
        dataProvider: fastApiDataProvider({ primaryKey: 'email' })(`${CONFIG.BACKEND_URL}/identity/v1`, httpClient),
        provider: 'identity--email-primary',
        resources: ['validated-user-data'],
    },
    {
        dataProvider: mongoExpressDataProvider(`${CONFIG.BACKEND_URL}/notifications`, httpClient),
        provider: 'notifications',
        resources: ['notifications'],
    },
];

const apiDataProvider = (type: string, resource: string, params: object) => {
    const dataProviderMapping = dataProviders.find((dp) => dp.resources.includes(resource));
    if (!dataProviderMapping) {
        return fastApiDataProvider()(`${CONFIG.BACKEND_URL}`, httpClient);
    }

    const mappingType: { [key: string]: string } = {
        [GET_LIST]: 'getList',
        [GET_ONE]: 'getOne',
        [GET_MANY]: 'getMany',
        [GET_MANY_REFERENCE]: 'getManyReference',
        [CREATE]: 'create',
        [UPDATE]: 'update',
        [UPDATE_MANY]: 'updateMany',
        [DELETE]: 'delete',
    };

    return dataProviderMapping.dataProvider[mappingType[type]](resource, params);
};

function App() {
    return (
        <Admin dataProvider={apiDataProvider} authProvider={authProvider}>
            <Resource name='users' list={UsersList} edit={UserEdit} icon={PeopleIcon} />
            <Resource
                name='external-users'
                list={ExternalUsersList}
                create={ExternalUserCreate}
                edit={ExternalUserEdit}
                icon={PeopleIcon}
            />
            <Resource
                name='validated-user-data'
                list={ValidatedUserList}
                create={ValidatedUserCreate}
                edit={ValidatedUserEdit}
                icon={PeopleIcon}
                options={{ id: 'email' }}
            />
            <Resource name='permissions' list={PermissionsList} create={PermissionCreate} edit={PermissionEdit} />
            <Resource name='groups' list={GroupsList} create={GroupCreate} edit={GroupEdit} />
            <Resource name='notifications' list={NotificationsList} />
        </Admin>
    );
}

export default App;
