import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const ValidatedUserCreate = (props: any) => {
    return (
        <Create title='Create a ValidatedUser' {...props}>
            <SimpleForm>
                <TextInput source='email' />
                <TextInput source='type' />
                <TextInput source='subtype' />
            </SimpleForm>
        </Create>
    );
};

export default ValidatedUserCreate;
